<template>
  <ion-header class="ion-no-border">
    <ion-toolbar mode="md">
      <ion-title v-if="!id"
        ><strong>{{ formTitle }}</strong></ion-title
      >
      <ion-title v-else
        ><strong>{{ name }}</strong> #{{ id }}</ion-title
      >
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">
          <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item>
      <ion-label position="floating">Lanaren izena</ion-label>
      <ion-input
        :value="name"
        @ionInput="name = $event.target.value"
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Frekuentzia</ion-label>
      <ion-input
        :value="frecuency"
        @ionInput="frecuency = $event.target.value"
      ></ion-input>
    </ion-item>
  </ion-content>

  <ion-footer class="ion-no-border" style="padding: 15px">
    <ion-toolbar>
      <div class="buttons-footer">
        <ion-button @click="saveHandle" mode="ios" size="medium"
          >Lan mota gorde
        </ion-button>

        <ion-button
          v-if="id"
          @click="deleteHandle(id)"
          color="danger"
          mode="ios"
          size="medium"
          >Lana ezabatu
        </ion-button>
      </div>

      <messages v-if="toast" :toast="toast" @closeToast="closeToast"></messages>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonItem,
  IonButtons,
  IonButton,
  IonLabel,
  IonInput,
  IonIcon,
  modalController,
  loadingController,
} from "@ionic/vue";
import { closeOutline } from "ionicons/icons";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, onMounted, watch, computed } from "vue";

import errors from "@/middlewares/errors.js";
import Messages from "@/components/Messages.vue";

export default {
  name: "JobTermForm",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonItem,
    IonButtons,
    IonButton,
    IonLabel,
    IonInput,
    IonIcon,
    Messages,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const formTitle = ref(props.title);
    const jobTerms = ref([]);
    const isDefault = ref(true);
    const id = ref(null);
    const clientId = ref(null);
    const name = ref(null);
    const frecuency = ref(null);
    const toast = ref(null);
    const api = ref(process.env.VUE_APP_API);

    onMounted(() => {
      if(props.job){
        id.value = props.job.id;
        clientId.value = props.job.client_id;
        name.value = props.job.name;
        frecuency.value = props.job.frecuency;
      }
    });

    const getClient = computed(() => store.state.getClient);
    const getJobTerms = computed(() => store.state.getJobTerms);

    watch(getClient, () => {
      modalController.dismiss();
    });

    watch(getJobTerms, (value) => {
      jobTerms.value = value;
    });

    const getTerm = (val) => {
      return getJobTerms.value.find((res) => {
        if (res.id === val) return res;
      });
    };

    const closeToast = () => {
      toast.value = null;
    };

    const closeModal = async () => {
      return modalController.dismiss();
    };

    const getArchive = async (id) => {
      router.push(`${api.value}jobs-archive/${id}`);
      modalController.dismiss();
    };

    const saveHandle = async () => {
      const loading = await loadingController.create({
        mode: "ios",
      });

      await loading.present();

      let params = {
        id: id.value,
        name: name.value,
        frecuency: frecuency.value,
      };

      if (!name.value || !name.value) {
        toast.value = {
          type: "danger",
          message: "Beharrezko eremu guztiak bete",
        };
        loading.dismiss();
        return true;
      }
      axios
        .post(`${api.value}job-terms`, params)
        .then((res) => {
          if (res.data.error) {
            toast.value = {
              type: "danger",
              message: `Erroreak errepasatu: ${errors(res.data.error)}`,
            };
            loading.dismiss();
            return true;
          }

          store.dispatch("_clientReload", clientId.value).then(() => {
            store.dispatch("_getJobTerms");
            store.dispatch("_jobsReload");
          });

          toast.value = {
            type: "success",
            message: "Lan motaren datuak gorde dira",
          };

          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    const deleteHandle = async () => {
      const loading = await loadingController.create({
        mode: "ios",
      });

      await loading.present();

      axios
        .delete(`${api.value}job-terms/${id.value}`)
        .then(() => {

          store.dispatch("_clientReload", clientId.value).then(() => {
            store.dispatch("_getJobTerms");
            store.dispatch("_jobsReload");
          });

          toast.value = {
            type: "success",
            message: "Bezeroaren datuak gorde dira",
          };
          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    return {
      router,
      jobTerms,
      formTitle,
      saveHandle,
      deleteHandle,
      getArchive,
      closeModal,
      closeOutline,
      name,
      isDefault,
      id,
      clientId,
      frecuency,
      getTerm,
      closeToast,
      toast,
    };
  },
};
</script>

<style lang="scss">
.buttons-footer {
  display: flex;
  justify-content: space-between;
  ion-button {
    width: 100%;
  }
}
</style>
