import 'es6-promise/auto'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import 'animate.css';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';


import axios from 'axios';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

axios.interceptors.request.use(
  async (config) => {
    let token = await Storage.get({ key: '_userToken' });

    if (token.value) {
      config.headers['Authorization'] = `Bearer ${ JSON.parse(token.value).token }`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router);

router.isReady().then(() => {
  app.mount('#app');
});
