import { createStore } from 'vuex'
import axios from 'axios'

const api = process.env.VUE_APP_API



// Create a new store instance.
const store = createStore({
  state () {
    return {
      token: null,
      user: null,
      sensor: false,
      getClient: null,
      getClients: null,
      setClientItem: null,
      getJobs: null,
      getJobTerms: null,
      getClientsReset: null,
      getPaymentsReset: false,
      getPayments: null,
      getArchive: null,
      jobsNotification: 0,
      currentRoute: null
    }
  },
  mutations: {
    _LOGIN (state, value) {
      state.token = value.access_token,
      state.user = value.user
    },
    _SENSOR (state, value) {
      state.sensor = value
    },
    _LOGOUT (state) {
      state.token = null
    },
    _CLIENT_RELOAD (state, value) {
      state.getClient = value
    },
    _JOBS_RELOAD (state, value) {
      state.getJobs = value
    },
    _SET_JOB_TERMS(state, value) {
      state.getJobTerms = value
    },
    _CLIENT_ITEM (state, value) {
      state.setClientItem = value
    },
    _GET_CLIENTS (state, value) {
      state.getClients = value
    },
    _RESET_CLIENTS(state, value) {
      state.getClientsReset = value
    },
    _GET_PAYMENTS (state, value) {
      state.getPayments = value
    },
    _GET_ARCHIVE(state, value) {
      state.getArchive = value
    },
    _RESET_PAYMENTS(state, value) {
      state.getPaymentsReset = value
    },
    _JOBS_NOTIFICATION(state, value) {
      state.jobsNotification = value
    },
    _CURRENT_ROUTE(state, value) {
      if (value) {
        state.currentRoute = value
      } else {
        state.currentRoute = null
      }

    }
  },
  actions: {
    async _login({commit}, credentials) {
      return axios.post(`${api}login`, credentials)
        .then(res => {
          if(res.data.status_code === 200)
            commit('_LOGIN', res.data)
          return res
        })
    },
    async _clientReload({ commit }, client) {
      return axios.get(`${api}clients/get/${client}`)
        .then(res => {
          commit('_CLIENT_RELOAD', res.data.result[0])
          return res
        })
    },
    async _getJobTerms({ commit }) {
      return axios.get(`${api}job-terms`).then((res) => {
        commit('_SET_JOB_TERMS', res.data.result)
        return res
      });

    },
    async _jobsReload({ commit }) {
      return axios.get(`${api}jobs`)
        .then(res => {

          let count = 0;
          res.data.result.data.map(res => {
            //days between last update and now
            let initDate = new Date().getTime();
            let difference
            let endDate
            let dateDiff 
            let finalDifference 
            
            // If special date
            if (res.special_date) {
              
              endDate         = new Date(res.special_date.slice(0, 10)).getTime();
              dateDiff        = endDate - initDate;
              finalDifference = parseInt(dateDiff / (1000 * 60 * 60 * 24)); //(1000*60*60*24) --> milisegundos -> segundos -> minutos -> horas -> días
              res['difference'] = finalDifference+1
              difference = finalDifference+1

            }
            // If frecuency
            else {
              endDate         = new Date(res.last_work.slice(0, 10)).getTime();
              dateDiff        = initDate - endDate;
              finalDifference = parseInt(dateDiff/(1000*60*60*24)); //(1000*60*60*24) --> milisegundos -> segundos -> minutos -> horas -> días

              if (res.is_default) {
                // difference between num of days and the frecuency
                res['difference'] = res.job_term.frecuency - finalDifference
                difference = res.job_term.frecuency - finalDifference

              } else {
                // difference between num of days and the frecuency
                res['difference'] = res.frecuency - finalDifference
                difference = res.frecuency - finalDifference
              }
            }

            if (difference <= 3) count++;
          })

          let save = res.data.result.data.sort((a,b) => (a.difference > b.difference) ? 1 : ((b.difference > a.difference) ? -1 : 0))

          commit('_JOBS_NOTIFICATION', count);
          commit('_JOBS_RELOAD', save);
          return res;
        })
    },
    async _getClients({ commit }, pageNumber) {
      let array = []
      return axios.get(`${api}clients/get?page=${pageNumber}`)
        .then(posts => {
            posts.data.result.data.forEach(res => {
              array.push(res)
            })
            commit('_GET_CLIENTS', array)
            return posts
        })
        .catch(() => {
        })
    },
    async _getPayments({ commit }, pageNumber) {
      let paymentsArray = []
      return axios.get(`${api}payments?page=${pageNumber}`)
        .then(posts => {
            posts.data.result.data.forEach(res => {
              paymentsArray.push(res)
            })
            commit('_GET_PAYMENTS', paymentsArray)
            return posts
        })
        .catch(() => {
        })
    },

    _getArchive({ commit }, [clientId, pageNumber]) {     
      let archivesArray = []
      return axios.get(`${api}jobs-archive/${clientId}?page=${pageNumber}`)
        .then(posts => {
          posts.data.result.data.forEach(res => {
              archivesArray.push(res)
            })
          commit('_GET_ARCHIVE', archivesArray)
          return posts
        })
        .catch(() => {
        })
    },
    async _logout({commit}) {
      // return axios.post('https://laravel.biklik.eus/api/logout', credentials)
      //   .then(res => {
      //     console.log('store', res.data)
      //     if(res.data.status_code === 200)
      //       commit('_LOGIN', res.data.access_token)
      //
      //     return res
      //   })
      //   .catch(err => {
      //     console.log('error', err)
      //   })

      commit('_LOGOUT')

    },


  }
})

export default store
