<template>
  <div class="wrapper">
    <ion-app>
      <div :class="{ 'active-tabs': tabRoute }">
        <ion-router-outlet animated="false" />
      </div>
      <tabs v-if="tabRoute" :route="nextRoute" />
    </ion-app>
  </div>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, onMounted } from "vue";

import { useStore } from "vuex";
import { computed, watch } from "vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { Plugins } from "@capacitor/core";
import { StatusBar } from "@ionic-native/status-bar";

import Tabs from "@/components/Tabs.vue";

// set status bar to white
StatusBar.backgroundColorByHexString("#84d728");

const { Storage } = Plugins;
//import { useRouter } from 'vue-router'

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    Tabs,
    IonRouterOutlet,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const tabRoute = ref(false);
    const nextRoute = ref(null);

    onMounted(async () => {
      let token = await Storage.get({ key: "_userToken" });
      if (token.value) store.commit("_LOGIN", JSON.parse(token.value));

      let sensor = await Storage.get({ key: "_sensor" });
      if (sensor.value) store.commit("_SENSOR", sensor.value);

      if (token.value) {
        // get job terms
        await store.dispatch("_getJobTerms");
        // get jobs
        await store.dispatch("_jobsReload");
        // get Clients
        await store.dispatch("_getClients", 1);
        // get Payments
        await store.dispatch("_getPayments", 1);
      }

      store.commit("_CURRENT_ROUTE", route.name);
    });

    watch(route, (value) => {
      store.commit("_CURRENT_ROUTE", value.name);
    });

    const getRoutes = computed(() => store.state.currentRoute);

    watch(getRoutes, (currentRoute) => {
      if (
        currentRoute == "home" ||
        currentRoute == "jobs" ||
        currentRoute == "clients" ||
        currentRoute == "job-terms" ||
        currentRoute == "payments"
      ) {
        tabRoute.value = true;
      } else {
        tabRoute.value = false;
      }
      setTimeout(() => {
        nextRoute.value = currentRoute;
      }, 10);
    });

    return {
      tabRoute,
      getRoutes,
      nextRoute,
    };
  },
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap");
@import "./theme/theme.scss";

* {
  font-family: "Nunito" !important;
}

:root {
  --ion-color-primary: #4f8c42 !important;
  --ion-color-secondary-rgb: 0, 102, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #005a00;
  --ion-color-secondary-tint: #1a751a;
}

.active-tabs div.ion-page {
  bottom: 60px !important;
}

@import "@/theme/theme.scss";
.toolbar-title-default {
  box-shadow: none !important;
}

.custom-header {
  background-image: linear-gradient(120deg, $primary, $secondary);
  color: white;
}
</style>
