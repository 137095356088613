const errors = (errors) => {
  let p = errors
  let errorsOut = []
  errorsOut = '<ul>'
  for (var key of Object.keys(p)) {
      errorsOut += `<li>${p[key]}</li>`
  }
  errorsOut += '</ul>'
  return errorsOut
}

export default errors
