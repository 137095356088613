<template>
  <div class="container">
    <div class="form-content">
      <ion-item>
        <ion-label position="floating">Izen-abizenak</ion-label>
        <ion-input
          :value="name"
          @ionInput="name = $event.target.value"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Emaila</ion-label>
        <ion-input
          :value="email"
          @ionInput="email = $event.target.value"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Telefonoa</ion-label>
        <ion-input
          :value="phone"
          @ionInput="phone = $event.target.value"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Helbidea</ion-label>
        <ion-textarea
          :value="address"
          @ionInput="address = $event.target.value"
        >
        </ion-textarea>
      </ion-item>
    </div>

    <ion-button @click="saveHandle" expand="block" mode="ios" size="medium"
      >Aldaketak gorde
    </ion-button>

    <ion-button
      v-if="id"
      @click="deleteHandle"
      expand="block"
      mode="ios"
      color="danger"
      size="medium"
      >Ezabatu
    </ion-button>

    <messages v-if="toast" :toast="toast" @closeToast="closeToast"></messages>
  </div>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  loadingController,
} from "@ionic/vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import errors from "@/middlewares/errors.js";
import Messages from "@/components/Messages.vue";

import axios from "axios";
import { ref, onMounted } from "vue";
export default {
  name: "ClientForm",
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    Messages,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const id = ref(null);
    const name = ref(null);
    const email = ref(null);
    const phone = ref(null);
    const address = ref(null);
    const toast = ref(null);
    const api = ref(process.env.VUE_APP_API);

    onMounted(() => {
      if (props.client) {
        id.value = props.client.id;
        name.value = props.client.name;
        email.value = props.client.email;
        phone.value = props.client.phone;
        address.value = props.client.address;
      }
    });

    const closeToast = () => {
      toast.value = null;
    };

    const saveHandle = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        mode: "ios",
      });

      await loading.present();

      let params = {
        id: id.value,
        name: name.value,
        email: email.value,
        phone: phone.value,
        address: address.value,
      };
      if (!name.value || !email.value || !phone.value || !address.value) {
        toast.value = {
          type: "danger",
          message: "Bete beharrezko eremuak",
        };
        loading.dismiss();
        return true;
      }

      let method = "create";
      if (id.value) {
        method = "update";
      }
      axios
        .post(`${api.value}${method}`, params)
        .then((res) => {
          if (res.data.error) {
            toast.value = {
              type: "danger",
              message: `Erroreak errepasatu:
                ${errors(res.data.error)}`,
            };
            loading.dismiss();
            return true;
          }

          if (id.value) store.dispatch("_clientReload", id.value);
          store.commit("_RESET_CLIENTS", "change"); // reset the list

          toast.value = {
            type: "success",
            message: "Bezeroaren datuak gorde dira",
          };
          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    const deleteHandle = async () => {
      const loading = await loadingController.create({
        mode: "ios",
      });

      let del = confirm("Bezeroa eta bere lan guztiak ezabatu nahi al dituzu?");

      if (!del) return true;

      await loading.present();

      axios
        .delete(`${api.value}client/${id.value}`)
        .then(() => {
          store.commit("_RESET_CLIENTS", "change"); // reset the list
          store.commit("_RESET_PAYMENTS", true); // reset the list
          store.dispatch("_jobsReload");
          loading.dismiss();
          toast.value = {
            type: "success",
            message: "Bezeroa ezabatu da. Berbidalia izango zara...",
          };
          setTimeout(() => {
            router.go(-1);
          }, 5000);
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    return {
      saveHandle,
      deleteHandle,
      id,
      name,
      email,
      phone,
      address,
      toast,
      closeToast,
    };
  },
};
</script>

<style lang="scss">
.container {
  padding: 20px;
}

.form-content {
  margin: 0 0 30px 0;
}
</style>
