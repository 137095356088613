<template>
  <ion-header class="ion-no-border">
    <ion-toolbar mode="md">
      <ion-title v-if="!currentClient.id"
        ><strong>Bezero berria</strong></ion-title
      >
      <ion-title v-else><strong>Bezeroa editatu</strong></ion-title>
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">
          <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <client-form :client="currentClient"></client-form>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { closeOutline } from "ionicons/icons";
import { ref, onMounted } from "vue";
import ClientForm from "@/components/forms/ClientForm.vue";
export default {
  name: "NewClient",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    ClientForm,
    IonIcon,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const currentClient = ref(props.client);

    onMounted(() => {
      if (props.client) currentClient.value = props.client;
    });

    const closeModal = async () => {
      return modalController.dismiss();
    };

    return {
      currentClient,
      closeModal,
      closeOutline,
    };
  },
};
</script>
