<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="md" color="dark">

        <ion-buttons slot="start" @click="router.go(-1)">
          <ion-button v-if="!loading">
            <ion-icon slot="icon-only" :icon="arrowBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        
        <ion-title v-if="!search"><strong>Lan motak</strong></ion-title>

        <ion-buttons slot="end">
          <ion-button @click="searchBox">
            <ion-icon
              v-if="!search"
              slot="icon-only"
              :icon="searchOutline"
            ></ion-icon>
            <ion-icon v-else slot="icon-only" :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-searchbar
          v-if="search"
          :value="searchTerm"
          @ionInput="searchTerm = $event.target.value"
          show-cancel-button="never"
          mode="md"
          placeholder="Lan motak bilatu"
        >
        </ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content color="tertiary">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list
        v-if="!loading"
        style="padding-top: 0; padding-bottom: 0;"
        mode="ios"
      >
        <ion-item
          v-for="item in items"
          :key="item"
          color="transparent"
          lines="full"
          @click="_editJob(item)"
        >
          <ion-label>
            <strong>{{ item.name }}</strong>
            <p>
              <span>({{ item.frecuency }} egunero)</span>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-list
        v-else
        style="padding-top: 0; padding-bottom: 0;"
        mode="ios"
        lines="full"
        inset="false"
      >
        <ion-item
          v-for="item in 20"
          :key="item"
          color="transparent"
          lines="full"
        >
          <ion-label>
            <h3>
              <ion-skeleton-text
                animated
                style="width: 60%"
              ></ion-skeleton-text>
            </h3>
          </ion-label>

          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
        </ion-item>
      </ion-list>

      <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        @click="_editJob()"
        mode="ios"
      >
        <ion-fab-button color="dark">
          <ion-icon :icon="addOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonButtons,
  IonButton,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  IonSearchbar,
  modalController,
} from "@ionic/vue";

import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import JobTermForm from "@/components/forms/JobTermForm.vue";

import {
  arrowBackOutline,
  ellipsisHorizontal,
  addOutline,
  homeOutline,
  checkmarkCircle,
  warning,
  closeOutline,
  searchOutline,
} from "ionicons/icons";
export default {
  name: "Jobs",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonList,
    IonButtons,
    IonButton,
    IonRefresher,
    IonRefresherContent,
    IonSkeletonText,
    IonSearchbar,
  },
  setup() {
    const loading = ref(true);
    const search = ref(false);
    const isDisabled = ref(false);
    //const perPage = ref(50);
    const store = useStore();
    const router = useRouter();
    const items = ref(null);
    const searchTerm = ref(null);

    const searchBox = () => {
      search.value = !search.value;
      if (!search.value) {
        searchTerm.value = "";
      }
    };

    const doRefresh = (ev) => {
      store.dispatch("_getJobTerms").then(() => {
        ev.target.complete();
      });
    };

    const getJobTerms = computed(() => store.state.getJobTerms);

    onMounted(() => {
      items.value = getJobTerms.value;
      loading.value = false;
    });

    watch(getJobTerms, (value) => {
      items.value = [];
      items.value = value;
      loading.value = false;
    });

    // searchbar
    watch(searchTerm, (value) => {
      if (value.length > 0 && value.length < 4) return;
      let search = value.toLowerCase();

      let array = items.value.filter((find) => {
        return find.name.toLowerCase().includes(search);
      });
      items.value = array;

      if (value.length == 0) {
        store.dispatch("_getJobTerms");
      }
    });

    const _editJob = async (job = false) => {
      const modal = await modalController.create({
        component: JobTermForm,
        componentProps: {
          title: "Lan mota berria",
          job: job,
        },
      });
      return modal.present();
    };

    return {
      router,
      isDisabled,
      items,
      doRefresh,
      arrowBackOutline,
      ellipsisHorizontal,
      addOutline,
      homeOutline,
      checkmarkCircle,
      warning,
      _editJob,
      loading,
      closeOutline,
      search,
      searchTerm,
      IonSearchbar,
      searchOutline,
      searchBox,
    };
  },
};
</script>

<style lang="scss">
@import "@/theme/theme.scss";
.toolbar-title-default {
  box-shadow: none !important;
}

.header-gradient {
  background-image: linear-gradient(45deg, red, black);
}
</style>
