<template>
  <div class="error-image">
    <img v-if="image" src="@/assets/gardener.png" class="animate__animated animate__zoomIn"/>
    <h2 class="animate__animated animate__zoomIn">{{ title }}</h2>
    <p class="animate__animated animate__zoomIn">{{ message }}</p>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Errors",
  props: {
    options: {
      type: Object
    }
  },
  setup(props) {
    const title = ref(props.options.title)
    const message = ref(props.options.message)
    const image = ref(props.options.image)
    return {
      title,
      message,
      image
    };
  },
});
</script>

<style lang="scss">

.error-image {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  img {
    max-width: 50%;
  }

  h2 {
    font-weight: bolder;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 10px 0;
    padding: 0;
  }
}

</style>
