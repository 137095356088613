import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export default async function auth({ next, router }) {
  let userToken = await Storage.get({ key: '_userToken' })
  let sensor = await Storage.get({ key: '_sensor' })

  if (!JSON.parse(userToken.value)) {
    return router.push('/');
  }

  if (JSON.parse(sensor.value) && JSON.parse(sensor.value).pass) {
    return router.push('/home');
  }

  return next();
}
