import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export default async function logged({ next, router }) {
  let userToken = await Storage.get({ key: '_userToken' })
  let sensor = await Storage.get({ key: '_sensor' })

  await Storage.set({
    key: "_sensor",
    value: JSON.stringify({
      pass: false,
    })
  });
  
  if (JSON.parse(userToken.value) && JSON.parse(sensor.value) && !JSON.parse(sensor.value).pass) {
    return router.push('/fingerprint');
  }

  if (JSON.parse(userToken.value) && JSON.parse(sensor.value) && JSON.parse(sensor.value).pass) {
    return router.push('/home');
  }

  return next();
}
