<template>
  <div class="tabs">
    <div
      class="tabs__btn"
      v-for="(link, index) in links"
      :key="index"
      @click="changeRoute(link.link)"
      :class="{ active: active == link.link }"
    >
      <div>
        <span class="tabs__icon-content">
          <ion-icon :icon="link.icon" />
          <!-- <div class="notify" v-if="link.link == 'jobs' && jobsCounter > 0">
            {{ jobsCounter }}
          </div> -->
        </span><br />
        {{ link.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { ref, computed, watch } from "vue";
import { notifications, people, card, homeOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const active = ref(null);
    const links = ref([
      {
        name: "Home",
        link: "home",
        icon: homeOutline,
      },
      {
        name: "Lanak",
        link: "jobs",
        icon: notifications,
      },
      {
        name: "Bezeroak",
        link: "clients",
        icon: people,
      },
      {
        name: "Ordainketak",
        link: "payments",
        icon: card,
      },
    ]);
    const changeRoute = (go) => {
      router.push(go);
    };

    const nextRoute = computed(() => props.route);

    watch(nextRoute, (value) => {
      active.value = value;
    });

    return {
      router,
      active,
      changeRoute,
      links,
      jobsCounter: computed(() => store.state.jobsNotification),
      notifications,
      people,
      card,
      homeOutline,
    };
  },
});
</script>

<style lang="scss">
.tabs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 5px;
  background-image: linear-gradient(45deg, white, #f2f2f2);
  z-index: 1;

  &__icon-content {
    position: relative;
  }
  &__btn {
    color: lighten(black, 15%) !important;
    width: 100%;
    padding: 3px;
    text-align: center;
    font-size: 13px;
    display: flex !important;
    flex-direction: column !important;
    border: 0 !important;
    transition: all 0.1s;
    &-text {
      display: block;
    }
    ion-icon {
      font-size: 24px;
    }

    &.active {
      color: var(--ion-color-primary) !important;
      transform: scale(1.06);
    }

    position: relative;
    .notify {
      position: absolute;
      font-size: 12px;
      font-weight: bold;
      height: 18px;
      min-width: 18px;
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -15px;
      right: -15px;
      border-radius: 20px;
      border: 1px solid white;
      color: white;
      text-align: center;
      background: #eb445a;
    }
  }
}
</style>
