<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="md" class="custom-header">
        <ion-title v-if="!search"><strong>Bezeroak</strong></ion-title>

        <ion-buttons slot="end">
          <ion-button @click="searchBox">
            <ion-icon
              v-if="!search"
              slot="icon-only"
              :icon="searchOutline"
            ></ion-icon>
            <ion-icon v-else slot="icon-only" :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-searchbar
          v-if="search"
          :value="searchTerm"
          @ionInput="searchTerm = $event.target.value"
          show-cancel-button="never"
          mode="md"
          placeholder="Bezeroak bilatu"
        >
        </ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content color="tertiary">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content> </ion-refresher-content>
      </ion-refresher>

      <ion-list
        v-if="!loading"
        style="padding-top: 0; padding-bottom: 0;"
        mode="ios"
      >
        <ion-item
          v-for="item in items"
          :key="item"
          color="transparent"
          lines="full"
          @click="_editUser(item.id)"
        >
          <ion-badge color="light" slot="start">#{{ item.id }}</ion-badge>

          <ion-label>
            <strong>{{ item.name }}</strong>
            <p>{{ item.phone }}</p>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-list
        v-else
        style="padding-top: 0; padding-bottom: 0;"
        mode="ios"
        lines="full"
        inset="false"
      >
        <ion-item
          v-for="item in 20"
          :key="item"
          color="transparent"
          lines="full"
        >
          <ion-skeleton-text
            animated
            style="width: 10%"
            slot="start"
          ></ion-skeleton-text>

          <ion-label>
            <h3>
              <ion-skeleton-text
                animated
                style="width: 70%"
              ></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text
                animated
                style="width: 40%"
              ></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-infinite-scroll
        @ionInfinite="loadData($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>

      <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        @click="addClient()"
        mode="ios"
      >
        <ion-fab-button>
          <ion-icon :icon="personAddOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonSkeletonText,
  modalController,
  loadingController,
} from "@ionic/vue";

import { ref, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import ClientNew from "@/components/ClientNew.vue";
import {
  arrowBackOutline,
  ellipsisHorizontal,
  personAddOutline,
  searchOutline,
  closeOutline,
} from "ionicons/icons";
export default {
  name: "Clients",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonRefresher,
    IonRefresherContent,
    IonSkeletonText,
    IonSearchbar,
  },
  setup() {
    const loading = ref(false);
    const isDisabled = ref(false);
    //const perPage = ref(50);
    const pageNumber = ref(1);
    const router = useRouter();
    const store = useStore();
    const search = ref(false);
    const searchTerm = ref(null);
    const api = ref(process.env.VUE_APP_API);

    //const api = ref(process.env.VUE_APP_API)
    const toggleInfiniteScroll = () => {
      isDisabled.value = !isDisabled.value;
    };
    const items = ref([]);
    const getClients = computed(() => store.state.getClients);
    const getClientsReset = computed(() => store.state.getClientsReset);
    const setClientItem = computed(() => store.state.setClientItem);

    onMounted(() => {
      items.value = getClients.value;
      loading.value = false;
    });

    watch(getClients, (value) => {
      if (pageNumber.value == 1) items.value = [];

      value.forEach((res) => {
        items.value.push(res);
      });

      loading.value = false;
      store.commit("_RESET_CLIENTS", null); // reset the list
    });

    watch(getClientsReset, (value) => {
      if (value == "change") {
        pageNumber.value = 1;
        store.dispatch("_getClients", pageNumber.value);
      }
    });

    // when edited
    watch(setClientItem, (editedValue) => {
      if (editedValue.id) {
        let index = items.value.findIndex((res) => res.id == editedValue.id);
        items.value[index] = editedValue;
      }
    });

    // searchbar
    watch(searchTerm, (value) => {
      if (value.length > 0 && value.length < 4) return;

      handleSearch();

      if (value.length == 0) {
        pageNumber.value = 1;
        store.dispatch("_getClients", pageNumber.value);
      }
    });

    const searchBox = () => {
      search.value = !search.value;
      if (!search.value) {
        searchTerm.value = "";
        handleSearch();
      }
    };

    const doRefresh = (ev) => {
      pageNumber.value = 1;
      store
        .dispatch("_getClients", 1)
        .then(() => {
          ev.target.complete();
          pageNumber.value++;
        })
        .catch(() => {
          isDisabled.value = true;
        });
    };

    const loadData = (ev) => {
      if (pageNumber.value == 1) pageNumber.value = 2;
      store
        .dispatch("_getClients", pageNumber.value)
        .then(() => {
          ev.target.complete();
          pageNumber.value++;
        })
        .catch(() => {
          isDisabled.value = true;
        });
    };

    const _editUser = async (id) => {
      const loadingPage = await loadingController.create({
        mode: "ios",
        translucent: false,
      });
      loadingPage.present();

      store.dispatch("_clientReload", id).then(() => {
        loading.value = false;
        loadingPage.dismiss();
        router.push("/client/" + id);
      });
    };

    const addClient = async () => {
      let client = {};
      const modal = await modalController.create({
        component: ClientNew,
        componentProps: {
          client: client,
        },
      });
      return modal.present();
    };

    const handleSearch = () => {
      pageNumber.value = 1;
      if (searchTerm.value.length < 4) return true;

      axios
        .get(`${api.value}clients/search/${searchTerm.value}`)
        .then((res) => {
          items.value = res.data.result;
        });
    };

    return {
      loading,
      isDisabled,
      toggleInfiniteScroll,
      loadData,
      items,
      doRefresh,
      arrowBackOutline,
      ellipsisHorizontal,
      personAddOutline,
      searchOutline,
      _editUser,
      handleSearch,
      addClient,
      search,
      searchTerm,
      closeOutline,
      store,
      router,
      searchBox,
    };
  },
};
</script>

<style lang="scss">
@import "@/theme/theme.scss";
.toolbar-title-default {
  box-shadow: none !important;
}
</style>
