<template>
  <ion-toast
    mode="ios"
    :is-open="isOpenRef"
    :message="message.message"
    :duration="5000"
    @onDidDismiss="close"
    :color="message.type"
    position="bottom"
  >
  </ion-toast>
</template>

<script>
import { IonToast } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  components: { IonToast },
  props: {
    toast: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {
    const message = ref(props.toast)
    const isOpenRef = ref(false);

    onMounted(() => {
        isOpenRef.value = true
    })

    const close = () => {
      isOpenRef.value = false
      emit('closeToast', true)
    }

    return {
        message,
        isOpenRef,
        close
    }
  }
});
</script>
